<template>
  <Page style="padding-top: 80px">
    <Article ref="article" :title="article.title" :title-image="article.titleImage" :time="article.formattedTime"
      :summary-text="article.summary">
      <!-- 正文 -->
      <Title size="3">
        纸质档案缺点：
      </Title>
      <Section >
        纸质档案是以纸张作为载体的一种档案，如保存备查的公文图纸、电报、户籍、帐册、合同等的原本和原稿。纸质档案具有易损坏，无法再生，需要保密等特点，对单位来说纸质档案的缺点更明显，主要有以下几点：
      </Section>
      <Section :image="require('../../../assets/information/article_6_1.png')">
        1、收集费钱：打印归档的会计资料，涉及多套业务系统，需要频繁切换，纸质凭证打印输出，浪费纸张，增加公司运营成本；
      </Section>
      <Section :image="require('../../../assets/information/article_6_2.png')">
        2、整理费时：财务凭证、财表归档装订复杂，不断重复进行档案录入操作，耗时长，易出错；手动打印、整理、装订、著录、造册工作量巨大，增加大量的人力物力；管理费心                       
      </Section>
      <Section >
        3、管理费心：实物库房归档保管，占用存储空间，需专人管理人管理成本高，受环境因素，年代久远或保管不善影响，会导致不同程度的损坏或丢失，重要财务凭证丢失将
      </Section>
      <Section >
        4、利用费事：实物纸质档案查阅繁琐，借阅不方便，统计也十分困难，档案借出状态无法准确及时掌握，实物凭证与业务分散，给需要审阅的人员增加工作量。
      </Section>

      <Title size="3">
        电子档案管理系统的优点：
      </Title>
      <Section >
        1、通过智能化手段将业务、财务工作内容进行结构化处理，数字化流转及影像化存储
      </Section>
      <Section >
        2、实现影像在线调阅、自动审核、智能查询
      </Section>
      <Section >
        3、打破组织边界，实现数据共享，降低成本、提高效率
      </Section>
      <Section :image="require('../../../assets/information/article_6_3.png')">
        4、实现电子会计档案合规合法；
      </Section>
      <Section>
        5、减少整理工作量，电子会计档案一键归档
      </Section>
      <Section>
         6、存储合规安全，比实物档案查找方便，档案借阅归还便捷合规
      </Section>
      <Section>
        7、档案移交移库便捷，降低实物管理成本；
      </Section>
      <Section>
        8、提升制证归档效率的同时，也方便检索利用查询，电子档案关联索引
      </Section>
      <Section>
        9、按需借阅，实时借阅异地借阅高效搜索，线上索引，并且无需实物归还，减少了大量的人力成本；
      </Section>
      <Section>
        10、对审计人员来说数据全面账目完整，查询便捷快速定位，数据合法，更便于审计；
      </Section>
      <Section>
        11、对稽查人员来说按需稽查快速定位，合法合规，数据溯源流程完整
      </Section>
      <Section>
        12、做到业档融合业档一体化，赋能业务，支撑合规；
      </Section>
      
      <Title size="3">
        全电发票时代
      </Title>
      <Section >
        全电发票时代，电子会计档案的合规管理，是不可触及的红线，企业务必要关注。现推荐链聚四库电子档案管理系统：
      </Section>
      <Section >
        ✦流程优化1秒归档，融合业财档系统，大幅降低人力、场地的投入，提升工作效率，为企业降本增效；
      </Section>
      <Section >
        ✦同时能精准定位3秒检索提升利用效率，全流程实现无纸化办公，减少耗材节能减排
      </Section>
      <Section >
         ✦用区块链技术第三方存证及防篡改；
        </Section>
      <Section :image="require('../../../assets/information/article_6_4.png')">
         ✦系统符合《电子档案管理系统通用功能要求》GB/T39784-2021，满足企事业单位电子会计档案的合规管理，是老板们真正需要的电子会计档案管理系统；
      </Section>
      
    </Article>
  </Page>
</template>
<script>

import { createArticle } from "../../../components/article-template/article-header.js";

//------------------------------------------------------------------------------
/**
 * 定义文章的简要信息；这些信息将会共享给“新闻资讯”页面用于组装为资讯列表；
 */

// 文章封面图需要直接导入；
import TITLE_IMAGE from "../../../assets/information/article_6_1.png";

// 创建不包含正文的文章头部；
const ARTICLE_HEADER = createArticle({
  title: "不知道纸质档案有多麻烦，就无法想象电子档案系统有多方便",
  time: { year: 2023, month: 2, day: 3 },
  titleImage: TITLE_IMAGE,
  summary:"电子档案管理系统和纸质档案管理的优缺点一次讲清楚，全电发票时代，电子会计档案的合规管理，是不可触及的红线，企业务必要关注。",
});

//------------------------------------------------------------------------------

export default {
  name: "Article-6",

  header: ARTICLE_HEADER,

  data() {
    return {
      article: ARTICLE_HEADER,
    };
  },
};
</script>
<style lang="less" scoped></style>
