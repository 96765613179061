<template>
  <Page style="padding-top: 80px">
    <Article ref="article" :title="article.title" :title-image="article.titleImage" :time="article.formattedTime"
      :summary-text="article.summary">
      <!-- 正文 -->
      <Title size="3">
        重要会议解读
      </Title>
      <Section :image="require('../../../assets/information/article_3_1.png')">
        财政部国家档案局2020年6号文件 ：
        <a href="https://www.saac.gov.cn/daj/tzgg/202003/8ce4e7837d49494b9e35b74124cf8547.shtml?ivk_sa=1024320u"
          target="_blank" class="a-link">
          https://www.saac.gov.cn/daj/tzgg/202003/8ce4e7837d49494b9e35b74124cf8547.shtml?ivk_sa=1024320u
        </a>
        财政部国家档案局2020年6号文件，此次通知明确了电子会计凭证为合规关键要素，单位以电子会计凭证的纸质打印件作为报销入账归档依据的，必须同时保存打印该纸质件的电子会计凭证；
      </Section>
      <Section :image="require('../../../assets/information/info_img3.png')">
        并规定了违反后的法律后果，单位和个人在电子会计凭证报销入账归档中存在违反本通知规定行为的，县级以上人民政府财政部门、档案行政管理部门应当依据《中华人民共和国会计法》等有关法律、行政法规处理处罚；
      </Section>
      <Title size="3">
        新闻资讯政策背景电子会计归档工作，已写入中央文件
      </Title>
      <Section :image="require('../../../assets/information/article_3_2.png')">

      </Section>
      <Title size="4">
        2021.6
      </Title>
      <Section :image="require('../../../assets/information/article_3_3.png')">
        中共中央办公厅国务院办公厅《十四五全国档案事业发展规划》
        <a href="https://www.saac.gov.cn/daj/toutiao/202106/ecca2de5bce44a0eb55c890762868683.shtml" target="_blank" class="a-link">
          https://www.saac.gov.cn/daj/toutiao/202106/ecca2de5bce44a0eb55c890762868683.shtml
        </a>
        中提出：<br />✦要求加强电子文件归档和电子档案移交接收。落实电子文件归档相关规定，建立健全电子文件归档、电子档案移交相关制度。全面开展电子档案移交接收工作，实现电子档案应收尽收。
      </Section>
      <Section>
        ✦加速数字档案馆（室）建设，推进机关、团体、企业事业单位和其他组织建设与业务系统相互衔接的电子档案管理信息系统，加强电子档案长期保存技术和管理研究，创建科学的可信存储与验证体系，保证电子档案真实性、完整性、可用性、安全性。
      </Section>
      <Title size="4">
        2021.3
      </Title>
      <Section :image="require('../../../assets/information/article_1_2.png')">
        中共中央办公厅国务院办公厅《关于进一步深化税收征收管理改革的意见》
        <a href="http://www.gov.cn/zhengce/2021-03/24/content_5595384.htm" target="_blank" class="a-link">
          http://www.gov.cn/zhengce/2021-03/24/content_5595384.htm
        </a>
        中明确了已下内容：
        <br />✦到2025年基本实现发票全领域、全环节、全要素电子化，着力降低制度性交易成本；
        <br />✦要求各地区各部门结合实际认真贯彻落实，大力推进会计核算和财务管理信息化，通过电子发票与财政支付、金融支付和各类单位财务核算系统、电子档案管理系统的衔接，加快推进电子发票无纸化报销、入账、归档、存储。
      </Section>
      <Title size="4">
        2021.2
      </Title>
      <Section :image="require('../../../assets/information/article_1_2.png')">
        国家税务总局、财政部、商务部、国家档案局《关于进一步扩大增值税电子发票电子化报销、入账、归档试点工作的通知
        <a href="http://www.gov.cn/zhengce/zhengceku/2021-02/22/content_5588305.htm" target="_blank" class="a-link">
          http://www.gov.cn/zhengce/zhengceku/2021-02/22/content_5588305.htm
        </a>
        2021年4月，链聚信息参与了全国第三批，湖南省首批电子发票电子化报销、入账、归档试点单位，并顺利通过试点。
      </Section>
      <Title size="3">
        企事业单位电子发票如何保管？如何推进归档？请关注链聚信息科技公众号了解更多电子档案归档内容和资讯！企事业单位电子发票如何保管？如何推进归档？请关注链聚信息科技了解更多电子档案归档内容和资讯！
      </Title>
    </Article>
  </Page>
</template>
<script>

import { createArticle } from "../../../components/article-template/article-header.js";

//------------------------------------------------------------------------------
/**
 * 定义文章的简要信息；这些信息将会共享给“新闻资讯”页面用于组装为资讯列表；
 */

// 文章封面图需要直接导入；
import TITLE_IMAGE from "../../../assets/information/info_img3.png";

// 创建不包含正文的文章头部；
const ARTICLE_HEADER = createArticle({
  title: "全电发票报销入账归档工作迫在眉睫",
  time: { year: 2023, month: 1, day: 15 },
  titleImage: TITLE_IMAGE,
  summary: "迎接全面数字化电子发票时代，到2025年基本实现发票全领域、全环节、全要素电子化，着力降低制度性交易成本；企事业单位全电发票报销入账归档工作迫在眉睫，以下是重要政策文件解读。",
});

//------------------------------------------------------------------------------

export default {
  name: "Article-3",

  header: ARTICLE_HEADER,

  data() {
    return {
      article: ARTICLE_HEADER,
    };
  },
};
</script>
<style lang="less" scoped>
.a-link{
  color: #2e64f3;
}
</style>
