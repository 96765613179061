<template>
  <div class="container">
    <Header :route-path="routePath" />
    <div class="main-container">
      <slot></slot>
    </div>
    <Footer />
  </div>
</template>
<script>
import { provide, reactive } from "vue";

import Header from "./header.vue";
import Footer from "./footer.vue";
// import Main from "./main.vue";

export default {
  name: "Page",
  components: {
    Header,
    Footer,
    // Main,
  },

  props: {
    routePath: {
      type: String,
    },
  },

  mounted() {
    const that = this;
    window.onresize = () =>
      (() => {
        that.$store.commit("increment", document.body.clientWidth);
      })();
  },

  setup() {
    const pageIndicator = {
      currentPageName: "",
    };

    provide("pageIndicator", reactive(pageIndicator));
  },
};
</script>

<style scoped>
#app {
  height: 100%;
  width: 100%;
}

.main-container {
  padding: 0px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  padding: 0px;
}
.el-affix {
  position: absolute;
  width: 100%;
  z-index: 2000;
}
</style>

<style>
/*阿里巴巴普惠体 2.0*/
/* @font-face {
  font-family: "AlibabaPuHuiTi-Thin";
  font-weight: 400;
  src: url("../assets/fonts/AlibabaPuHuiTi-2-35-Thin.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "AlibabaPuHuiTi-Light";
  font-weight: 400;
  src: url("../assets/fonts/AlibabaPuHuiTi-2-45-Light.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "AlibabaPuHuiTi-Regular";
  font-weight: 400;
  src: url("../assets/fonts/AlibabaPuHuiTi-2-55-Regular.woff2") format("woff2");
  font-display: swap;
} */

body {
  font-family: Source Han Sans CN-Medium, Source Han Sans CN, AlibabaPuHuiTi-Light, SimHei, Heiti, sans-serif, system-ui, Helvetica, Tahoma, Arial;
}
h1 {
  font-family: Source Han Sans CN-Medium, Source Han Sans CN, AlibabaPuHuiTi-Light, SimHei, Heiti, sans-serif, system-ui, Helvetica, Tahoma, Arial;
}
h2 {
  font-family: Source Han Sans CN-Medium, Source Han Sans CN, AlibabaPuHuiTi-Light, SimHei, Heiti, sans-serif, system-ui, Helvetica, Tahoma, Arial;
}
h3 {
  font-family: Source Han Sans CN-Medium, Source Han Sans CN, AlibabaPuHuiTi-Light, SimHei, Heiti, sans-serif, system-ui, Helvetica, Tahoma, Arial;
}
h4 {
  font-family: Source Han Sans CN-Medium, Source Han Sans CN, AlibabaPuHuiTi-Light, SimHei, Heiti, sans-serif, system-ui, Helvetica, Tahoma, Arial;
}
p {
  font-family: Source Han Sans CN-Medium, Source Han Sans CN, AlibabaPuHuiTi-Light, SimHei, Heiti, sans-serif, system-ui, Helvetica, Tahoma, Arial;
}
span {
  font-family: Source Han Sans CN-Medium, Source Han Sans CN, AlibabaPuHuiTi-Light, SimHei, Heiti, sans-serif, system-ui, Helvetica, Tahoma, Arial;
}
a {
  font-family: Source Han Sans CN-Medium, Source Han Sans CN, AlibabaPuHuiTi-Light, SimHei, Heiti, sans-serif, system-ui, Helvetica, Tahoma, Arial;
}
</style>
