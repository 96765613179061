import { utils } from "./utils.js";

// class ArticleHeader {
//   #title;

//   #time;

//   #formattedTime;

//   #yearMonth;

//   #titleImage;

//   /**
//    * 缩略图的文件路径；
//    */
//   #thumbnails;
//   /**
//    * 摘要；
//    */
//   #summary;

//   get title() {
//     return article.title;
//   }

//   get time() {
//     return article.time;
//   }

//   get formattedTime(){
//     return article.formattedTime;
//   }

//   get yearMonth(){
//     return article.yearMonth;
//   }

//   get titleImage() {
//     return article.titleImage;
//   }

//   get thumbnails() {
//     return article.thumbnails ? article.thumbnails : article.titleImage;
//   }

//   get summary() {
//     return article.summary;
//   }

//   constructor({ title, time, titleImage, thumbnails, summary }) {
//     article.title = title;
    
//     article.formattedTime = utils.formatTime(time);
//     article.yearMonth = utils.formatYearMonth(time);
//     article.time = Object.freeze(time);

//     article.titleImage = titleImage;
//     article.thumbnails = thumbnails;
//     article.summary = summary;

//     markRaw(this);
//   }


// }


function createArticle({ title, time, titleImage, thumbnails, summary }) {
  const article = {};
  article.title = title;
  
  article.formattedTime = utils.formatTime(time);
  article.yearMonth = utils.formatYearMonth(time);
  article.time = Object.freeze(time);

  article.titleImage = titleImage;
  article.thumbnails = thumbnails ? thumbnails : titleImage;
  article.summary = summary;

  Object.freeze(article);
  return article;
}

export { createArticle };
