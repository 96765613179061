<template>
  <div class="fs-footer fs-flex fs-flex-between fs-flex-align-center"
    :class="[(screenWidth < 850 && 'fs-flex-column') || '', (screenWidth < 850 && 'fs-flex-align-baseline') || '']">
    <div class="footer-box">
      <!-- logo -->
      <div v-if="isNormal" class="logo">
        <img src="@/assets/header/linkgie.png" style="width: 160px; height: 46px" />
        <div style="padding-top: 33px">
          <el-popover placement="top" :width="150" trigger="hover" popper-class="qr-popover">
            <template #reference>
              <img src="@/assets/footer/wechat.png" class="img-icon"
                style="width: 40px; height: 40px; margin-right: 20px" />
            </template>
            <template #default>
              <div class="popper-box">
                <img src="@/assets/footer/gzh.png" style="width: 110px; height: 110px" />
                <div style="text-align: center; width: 110px">微信公众号</div>
              </div>
            </template>
          </el-popover>

          <el-popover placement="top" :width="150" trigger="hover" popper-class="qr-popover">
            <template #reference>
              <img src="@/assets/footer/titok.png" class="img-icon"
                style="width: 40px; height: 40px; margin-right: 20px" />
            </template>
            <template #default>
              <div class="popper-box">
                <img src="@/assets/footer/dy.png" style="width: 110px; height: 110px" />
                <div style="text-align: center; width: 110px">抖音</div>
              </div>
            </template>
          </el-popover>

          <el-popover placement="top" :width="150" trigger="hover" popper-class="qr-popover">
            <template #reference>
              <img src="@/assets/footer/sp.png" class="img-icon" style="width: 40px; height: 40px; margin-right: 20px" />
            </template>
            <template #default>
              <div class="popper-box">
                <img src="@/assets/footer/sph.png" style="width: 110px; height: 110px" />
                <div style="text-align: center; width: 110px">视频号</div>
              </div>
            </template>
          </el-popover>
        </div>
      </div>
      <!-- 底边栏 -->
      <div class="links fs-flex fs-flex-between" :class="[(screenWidth < 500 && 'fs-flex-column') || '']">
        <div class="links-li">
          <div class="title">产品服务</div>
          <div class="item"><a class="item-link" href="/lowcode.html">星河低代码</a></div>
          <div class="item"><a class="item-link" href="/blockchain.html">千尺区块链</a></div>
          <div class="item"><a class="item-link" href="/archives.html">四库档案</a></div>
          <div class="item"><a class="item-link" href="/outsourcing.html">软件定制</a></div>
        </div>
        <div class="links-li">
          <div class="title">更多</div>
          <div class="item3"><a class="item-link" href="/about.html">关于我们</a></div>
          <!-- <div class="item">定价</div> -->
          <div class="item3"><a class="item-link" href="/download.html">下载中心</a></div>
          <div class="item3"><a class="item-link" href="/information.html">新闻资讯</a></div>
        </div>
        <div class="links-li">
          <div class="title">联系我们</div>
          <div class="item2">
            <div class="name">联系邮箱：</div>

            <div class="email">service@linkgie.com</div>
          </div>
          <!-- <div class="item3">售前咨询</div>
          <div class="item3">技术支持</div> -->
        </div>
      </div>

      <div v-if="isMobile" class="followus">
        <div class="qr-item">
          <img src="@/assets/footer/gzh.png" />
          <span class="title">-- 微信扫码 --</span>
          <span class="title">关注“链聚信息公众号”</span>
        </div>
        <div class="qr-item">
          <img src="@/assets/footer/sph.png" />
          <span class="title">-- 微信扫码 --</span>
          <span class="title">关注“链聚信息视频号”</span>
        </div>
        <div class="qr-item">
          <img src="@/assets/footer/dy.png" />
          <span class="title">-- 抖音扫码 --</span>
          <span class="title">关注“链聚信息抖音号”</span>
        </div>
      </div>
    </div>
    <div class="statements">
      <div class="statements-box">
        <div class="statement-item">
          <p style="float: left; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393; font-size: 14px">湖南链聚信息科技有限公司
            版权所有</p>
        </div>
        <div class="statement-item copyright">
          <p style="float: left; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393; font-size: 14px">
            Copyright © 2022 Linkgie.com. All Rights Reserved.
          </p>
        </div>

        <div class="statement-item">
          <a target="_blank" href=" http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=123456789"
            style="display: inline-block; text-decoration: none; line-height: 20px; display: flex; align-items: center">
            <img src="@/assets/icon/beian.png" style="float: left; width: 14px; height: 14px" />
            <p style="float: left; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393; font-size: 14px">湘公网安备
              43010402001124号</p>
          </a>
        </div>

        <div class="statement-item">
          <a target="_blank" href="https://beian.miit.gov.cn/"
            style="text-decoration: none; height: 20px; line-height: 20px">
            <p class="icp"
              style="float: left; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393; font-size: 14px">
              湘ICP备2022002882号</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import request from "@/utils/request";
import { mapGetters } from "vuex";
import { ResponsiveBase } from "../layout/reponsive-base";

export default {
  name: "Footer",
  mixins: [ResponsiveBase],
  data() {
    return {
      opacity: 0,
      drawer: false,
      buyOnline: {},
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["screenWidth"]),
  },
  mounted() { },
  methods: {
    handleScroll() {
      const top = document.documentElement.scrollTop; //获取scroll偏移值
      if (top > 0 && top <= 76) {
        //实际按需求取范围
        this.opacity = top / 76; //对opacity作计算，透明度从起始到1随偏移值而改变
      }
    },

    routerto(page) {
      // let routeInfo = this.$router.resolve({
      // path: "/archives",
      // query: { productId: info.id },
      // });
      // window.open(routeInfo.href);
      this.$router.push({ path: page });
    },
  },
};
</script>
<style lang="less" scoped>
.fs-footer {
  background-color: #ececec;
  padding: 56px 0 0 0;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  @media screen and (max-width: 850px) {
    height: fit-content;
    background-color: #4d577b;
  }

  .footer-box {
    width: 100%;
    max-width: 1280px;
    padding: 0 30px 30px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;

    @media screen and (max-width: 1280px) {
      // padding: 30px;
      padding: 0 30px 30px 30px;
      flex-wrap: wrap;
      row-gap: 30px;
    }
  }

  .logo {
    min-width: 160px;
  }

  .fs-itembar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 32px;
  }

  .fs-icon {
    background-color: transparent;

    a {
      display: flex;
    }

    img {
      width: auto;
      height: auto;
    }
  }

  .about-item {
    width: 47px;
    height: 47px;
    border: 1px solid #757575;
    border-radius: 50%;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .about-item:hover {
    background-color: #029fe7;
    border: 0px;

    cursor: pointer;

    img.online {
      content: url(../assets/icon/online-actived.png);
    }

    img.wechat {
      content: url(../assets/icon/wechat-actived.png);
    }

    img.video {
      content: url(../assets/icon/video-actived.png);
    }

    img.tiktok {
      content: url(../assets/icon/tiktok-actived.png);
    }

    img.redbook {
      content: url(../assets/icon/redbook-actived.png);
    }
  }

  p {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.links {
  width: 60%;
  max-width: 1920px;
  display: flex;

  @media screen and (max-width: 850px) {
    width: 100%;
    height: auto;
    justify-content: space-between;
    flex-direction: row;

    // padding: 0 30px;

    .links-li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: nowrap;
      row-gap: 15px;
    }

    .title {
      color: #f0f2f9 !important;
      font-size: 14px !important;
      line-height: 14px !important;
      margin: 0px !important;
    }

    .name {
      color: #f0f2f9 !important;
      font-size: 14px !important;
      line-height: 14px !important;
      margin: 0px !important;
    }

    .email {
      color: #f0f2f9 !important;
      font-size: 12px !important;
      line-height: 12px !important;
      margin: 0px !important;
    }

    .item {
      color: #f0f2f9 !important;
      font-size: 14px !important;
      line-height: 14px !important;
      margin: 0px !important;
    }

    .item3 {
      color: #f0f2f9 !important;
      font-size: 14px !important;
      line-height: 14px !important;
      margin: 0px !important;
    }
  }

  .links-li {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 20px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #040420;
      line-height: 20px;
      margin-bottom: 36px;
      letter-spacing: 1px;
    }

    .item {
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #727272;
      line-height: 16px;
      margin-bottom: 22px;
      cursor: pointer;
      width: 80px;
      text-align: justify;
      text-align-last: justify;
    }

    .item2 {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 12px;
      }
    }

    .name {
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #727272;
      line-height: 16px;
      // margin-bottom: 22px;
      cursor: pointer;
      width: auto;
      text-align: justify;
      text-align-last: justify;
    }

    .name:hover {
      color: #2e64f3;
    }

    .email {
      font-size: 15px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #727272;
    }

    .item3 {
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #727272;
      line-height: 16px;
      margin-bottom: 22px;
      cursor: pointer;
      width: 64px;
      text-align: justify;
      text-align-last: justify;
    }

    .item:hover {
      color: #2e64f3;
    }

    .item3:hover {
      color: #2e64f3;
    }

    .item-link {
      color: #727272;
      font-size: 16px !important;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      line-height: 16px;
      width: 80px !important;
      text-align: justify;
      text-align-last: justify;
    }

    .item-link:hover {
      color: #2e64f3;
    }
  }
}

.followus {
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: row;
  column-gap: 36px;
  flex-wrap: wrap;
  row-gap: 36px;

  span {
    color: #f0f2f9 !important;
  }

  .qr-item {
    width: 180px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    column-gap: 36px;
    row-gap: 8px;

    img {
      width: 120px;
      height: 120px;
      object-fit: fill;
    }

    .title {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      color: #727272;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    }
  }
}

.statements {
  width: 100%;
  background-color: #e4e4e4;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  padding: 0px;

  .statements-box {
    width: 100%;
    max-width: 1920px;
    min-height: 50px;

    padding: 12px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 18px;

    @media screen and (max-width: 850px) {
      flex-direction: column;
      row-gap: 8px;
      background-color: #4d577b;
      border-top-style: solid;
      border-top-width: 1px;
      border-top-color: #8c93ac;

      justify-content: center;
      padding: 12px 40px;
      margin-top: 0px;

      .statement-item p {
        color: #F0F2F9 !important;
      }
    }


    .statement-item .copyright {
      min-width: 360px;
    }

    span {
      font-size: 14px;
      /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
      font-weight: 400;
      color: #a3a3a3;
    }
  }
}

.icp:hover {
  text-decoration: underline;
}

.popper-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-icon:hover {
  border: 1px solid #9499a9;
  border-radius: 50%;
}
</style>
<style>
.qr-popover {
  min-width: 110px !important;
  width: 110px !important;
  height: 140px !important;
  padding: 5px !important;
}
</style>
