import { createApp } from 'vue'
// import router from './router'          //这里引入了router
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import Page from "./layout/page.vue";
import Article from "./components/article-template/article.vue";
import ArticleTitle from "./components/article-template/title.vue";
import ArticleSection from "./components/article-template/section.vue";


// 项目初始化css
import './global.less';

/**
 * 定义标准的入口函数；
 * 
 * @param {*} appComponent 应用入口组件；
 * @param {*} mountedElementId 应用入口组件挂接的 HTML 元素Id；默认为 "#app"
 */
function startApp(appComponent, mountedElementId){
  // 创建应用；
  const app = createApp(appComponent)

  //注册 Element 图标；
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component)
  }
  //注册 Element 组件；
  app.use(ElementPlus)

  // 注册通用组件；
  app.component(Page.name, Page);
  app.component(Article.name, Article);
  app.component(ArticleTitle.name, ArticleTitle);
  app.component(ArticleSection.name, ArticleSection);



  // app.use(router)
  app.use(store)
  if (! mountedElementId) {
    mountedElementId = "#app";
  }
  if (!mountedElementId.startsWith("#")) {
    mountedElementId = "#" + mountedElementId;
  }
  app.mount(mountedElementId);
}


export default startApp;
