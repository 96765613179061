<template>
  <div class="article-section">
    <div class="section-text-box">
      <slot :text="text">
        {{ text }}
      </slot>
    </div>
    <div v-if="image" class="section-image-box">
      <slot name="image" :image="image">
        <img class="section-image" :src="image" alt="" />
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Section",

  props: {
    text: {
      type: String,
    },

    image: {
      type: Object,
    },

    imageClass: {
      type: String,
      default: "section-image-box",
    },

    imageTitle: {
      type: String,
    },
  },

  inject: ["pageIndicator"],

  methods: {},
};
</script>
<style lang="less" scoped>
.article-section {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-text-box {
  width: 100%;
  font-size: 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #727272;
  line-height: 36px;
  padding: 10px 0px 10px;

  @media screen and (max-width:850px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.section-image-box {
  //width: 50%;
  height: fit-content;
  //margin-top: 26px;
  margin-bottom: 10px;
  @media screen and (max-width: 850px) {
    height: auto;
  }
}

.section-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 850px) {
  .article-section {
    font-size: 14px;
    //margin-top: 10px;
    line-height: 24px;
  }

  .article-section div {
    white-space: pre-wrap;
  }
}
</style>
