<template>
  <Page style="padding-top: 80px">
    <Article ref="article" :title="article.title" :title-image="article.titleImage" :time="article.formattedTime" :summary-text="article.summary">
      <!-- 正文 -->
      <Title size="3">
        公司简介
      </Title>
      <Section > 
        链聚信息科技是一家专注在低代码、云计算、区块链技术、软件外包领域, 提供创新、灵活和多样化的产品及服务的信息技术公司。我们拥有软件著作权、专利等多项知识产权成果，提供数字档案系统、低代码平台、企业级区块链平台及应用开发服务。
      </Section>
      <Title size="3">
        公司企业文化
      </Title>
      <Section > 
        公司核心团队由京东、美团等一线互联网公司的前高管、高级技术人员组成，具有领先的技术优势和丰富的技术经验。我们秉持“客户为先、创新引领”的工程师文化理念，为我们的客户提供卓越价值。企业文化：客户为先，创新致远，诚信共赢！企业使命：科技创造未来
      </Section>
      <Title size="3">
        产品业务
      </Title>
      <Section  :image="require('../../../assets/information/article_1_2.png')"> 
        公司坚持在区块链、云计算、大数据、移动互联、人工智能、软件外包、低代码等方面的持续创新和应用，在物流、通信、金融、电子、政务、智慧教育等领域研发出具有自主知识产权的解决方案和软件产品。
      </Section>
      <Title size="3">
        产品亮点
      </Title>
      <Section  :image="require('../../../assets/information/article_1_3.png')"> 
        四库数字化档案管理系统：能够为客户提供从纸质档案或电子档案的全生命周期管理；实现了电子档案收集、生成、归档、借阅、统计、利用、鉴定、销毁的全过程管理。让该归档的文件100
      </Section>
      <Section  :image="require('../../../assets/information/article_1_4.png')"> 
        星河低代码平台：面向开发者、开放、可扩展的企业级低代码平台平台优势如下：
      </Section>
      <Section  :image="require('../../../assets/information/article_1_5.png')"> 
        千尺链区块链平台：链聚信息打造了完全自主知识产权、高性能可扩展、产品化成熟的商用级区块链底层平台—千尺链。应用开发友好的智能合约、安全高效的共识算法、可靠的隐私保护、并行快速的多链，以及可扩展的跨链技术等创新；同时，经过大量实际业务积累，千尺链实现了产品化重要突破：应用可快速构建、可视化运维、技术合规及资金账户体系等，形成完整的产品服务能力。
      </Section>

      <Title size="3">
        公司成果
      </Title>
      <Section  :image="require('../../../assets/information/article_1_6.png')"> 
        ★链聚信息科技是湖南省首批，全国第三批电子会计档案报销、入账、归档试点单位，并于2021年11月份通过验收。<br/>★拥有千尺链区块链系统软件著作权。<br/>★拥有原子平台（星河低代码平台）软件著作权。<br/>★2022年6月27日取得发明专利证书：《一种基于区块链的零信任网络访问系统》。<br/>★目前公司拥有链聚、千尺等多项商标。%归档，让归档文件防篡改、三秒可查，并可第三方存证出证
      </Section>

      <Title size="3">
        创始人简介
      </Title>
      <Section > 
        黄海泉先生是链聚信息的创始人，任首席执行官(CEO），专注于科技产品创新与前沿技术趋势。此前曾任职京东科技集团区块链技术总监，人才委员会委员。任职期间创建了京东区块链平台 JDChain，获京东集团金项奖。 更早前曾服务于用友、中国电信等知名企业。黄海泉先生是信息技术领域的资深专家，有16+年技术研发管理经验，发明专利 27 项，擅长大型分布式系统和企业平台架构，还是开源的积极贡献者， 创建的开源项目 JDChain 是重要的国产自主区块链平台之一。
      </Section>
      <Section > 
        此外，还广泛参与国家技术标准制定与行业协会，积极推动技术与产业应用发展。
      </Section>
      <Section  :image="require('../../../assets/information/article_1_7.png')"> 
        •工信部“中国区块链技术和产业发展论坛”理事会工作组成员。<br/>•工信部“中国区块链开发大赛”第二、第三、第四届专家评委。<br/>•工信部《中国区块链技术和应用发展研究报告(2018)》编撰组成员。<br/>•《信息安全技术 区块链技术安全框架》国家标准起草专家组成员。<br/>• “中国工业与应用数学学会(CSIAM)”区块链专业委员会委员。
      </Section>
    </Article>
  </Page>
</template>
<script>

import { createArticle } from "../../../components/article-template/article-header.js";

//------------------------------------------------------------------------------
/**
 * 定义文章的简要信息；这些信息将会共享给“新闻资讯”页面用于组装为资讯列表；
 */

// 文章封面图需要直接导入；
import TITLE_IMAGE from "../../../assets/information/article_1_1.jpg";

// 创建不包含正文的文章头部；
const ARTICLE_HEADER = createArticle({
  title: "热烈庆祝链聚信息科技公众号上线",
  time: { year: 2022, month: 12, day: 6 },
  titleImage: TITLE_IMAGE,
  summary: "链聚信息科技是一家信息技术高科技公司；专注在低代码、云计算、区块链技术、软件外包领域。我们拥有软件著作权、专利等多项知识产权成果，提供数字档案系统、低代码平台、企业级区块链平台及应用开发服务。",
});

//------------------------------------------------------------------------------

export default {
  name: "Article-2",

  header: ARTICLE_HEADER,

  data() {
    return {
      article: ARTICLE_HEADER,
    };
  },
};
</script>
<style lang="less" scoped></style>
