<template>
  <Page style="padding-top: 80px">
    <Article ref="article" :title="article.title" :title-image="article.titleImage" :time="article.formattedTime"
      :summary-text="article.summary">
      <!-- 正文 -->
      <Title size="3">
        什么是全电发票？
      </Title>
      <Section :image="require('../../../assets/information/article_4_2.png')">
        全电发票是指依托可信身份体系和电子发票服务平台，以去介质、去版式、标签化、要素化、授信制、赋码制为基本特征，覆盖全领域、全环节、全要素的全新发票，与纸质发票具有同等法律效力。相较于传统纸质发票和电子发票，全电发票无需使用税控专业设备，无需办理发票票种核定，无需领用全电发票，系统自动赋予开票额度，大幅降低纳税人用票成本，提升开票便利度。
      </Section>

      <Title size="3">
        归档，是企业合规的必要条件之一
      </Title>
      <Section>
        随着互联网技术的不断进步，数字经济也日益变化，特别是区块链技术、元宇宙等新型技术活的蓬勃发展，传统的纸质发票很难满足经济社会发展的时代需求。
      </Section>
      <Section>
        与此同时，纸质发票的缺点也日渐突出，制作成本高、整理时间长、实物管理难、人力投入大等，这对企业来说增加了负担，同时也大大降低了工作效率。到2025年，进入全电发票时代，各企事业单位电子会计档案必须具备有效归档能力，这是企业合规的条件之一；
      </Section>
      
      <Title size="3">
        全电发票对企事业单位有哪些影响？
      </Title>
      <Section :image="require('../../../assets/information/article_4_1.png')">
        全电发票对企事业单位的影响：<br />
        ①全电发票突破了时间、空间的限制，给企事业单位带来了巨大的便利。<br />
        ②既有利于税务机关进行发票管理和统计，同时让企业经营成本降低，具有无可比拟的优势。<br />
        ③税收征纳成本更降低，全电发票无需领用、开具便捷、信息集成、节约成本。<br />
        ④纳税服务渠道更畅通。电子发票服务平台提供征纳互动相关功能，纳税人在业务处理过程中存在的问题能进行智能答疑。
      </Section>
      <Section>
        ⑤带来更深层次的税务稽核风险<br />
        金税四期下税务机关对企业税票的全生命周期进行实时监控，企业将面临更深层次的税务稽核风险。企业一旦出现税务合规或信用问题，可能会被处以降低开票额度、临时性中断开票权限等各类管控手段。<br />
        ⑥企业数据资产管理需升级<br />
        企业电子发票数量将会急剧增加，电子发票的入账归档成为了企业要解决的重要问题，企业需构建增专票电子发票能力（查重、验真、认证、入账、归档）系统，重构与优化面向全电发票的报账入账归档新流程。
      </Section>
      
      <Title size="3">
        企业如何做好电子会计归档工作？下篇给大家分享归档指南，请关注我们，给大家分享更多电子会计档案的信息！
      </Title>
    </Article>
  </Page>
</template>
<script>

import { createArticle } from "../../../components/article-template/article-header.js";

//------------------------------------------------------------------------------
/**
 * 定义文章的简要信息；这些信息将会共享给“新闻资讯”页面用于组装为资讯列表；
 */

// 文章封面图需要直接导入；
import TITLE_IMAGE from "../../../assets/information/article_4_1.png";

// 创建不包含正文的文章头部；
const ARTICLE_HEADER = createArticle({
  title: "80%的老板都不知道，全电发票对企事业单位的重要影响！",
  time: { year: 2023, month: 1, day: 18 },
  titleImage: TITLE_IMAGE,
  summary: "到2025年基本实现发票全领域、全环节、全要素电子化，需加快推进电子发票无纸化报销、入账、归档、存储。全电发票，将给企事业单位带来重要哪些影响？",
});

//------------------------------------------------------------------------------

export default {
  name: "Article-4",

  header: ARTICLE_HEADER,

  data() {
    return {
      article: ARTICLE_HEADER,
    };
  },
};
</script>
<style lang="less" scoped></style>
