<template>
  <Page style="padding-top: 80px">
    <Article ref="article" 
      :title="article.title" 
      :title-image="article.titleImage" 
      :time="article.formattedTime" 
      :summary-text="article.summary">
      <!-- 正文 -->
      <Title size="3">
        2021年4月参与试点
      </Title>
      <Section :image="require('../../../assets/information/article_2_2.png')"> 
        2021年4月，链聚信息科技参与了全国第三批，湖南首批电子发票电子化报销、入账归档工作。
      </Section>
      <Title size="3">
        试点工作实施过程
      </Title>
      <Title size="4">
        01 试点工作启动：2021.4-2021.5
      </Title>
      <Section > 
        公司成立了试点工作领导小组，开始了对试点工作要求相关文件学习和落实；
      </Section>
      <Title size="4">
        02 需求调研：2021.5-2021.7
      </Title>
      <Section  :image="require('../../../assets/information/article_2_3.png')"> 
        试点工作领导小组对内组织召开了3次内部研讨会，摸底系统和业务现状，制定项目推进方案；
      </Section>
      <Title size="4">
        03 电子发票报销入账和归档的流程设计解决：2021.6-2021.7
      </Title>
      <Section  > 
        形成了电子发票报销入账和归档全流程电子化和自动化的需求分析报告；
      </Section>
      <Title size="4">
        04 试点工作所需信息系统研发阶段：2021.6-2021.8
      </Title>
      <Section > 
        利用自主研发的区块链技术、分布式海量数据存储技术、高性能文档数据库等先进技术解决了电子会计档案电子数据易篡改、存储格式多元化等问题；
      </Section>
      <Title size="4">
        05 电子发票报销入账和归档全流程自动化测试阶段：2021.8-2021.9
      </Title>
      <Section > 
        通过制定流程模块和实施个性化流程配置，解决了发现的问题；
      </Section>
      <Title size="4">
        06 实际用应用阶段：2021.9-今
      </Title>
      <Section > 
        系统实现上线持续稳定运行，保障了公司全面的电子发票报销入账和归档全流程贯通。
      </Section>
      <Title size="3">
        试点验收通知
      </Title>
      <Title size="4">
        2021.11.23试点验收
      </Title>
      <Section  :image="require('../../../assets/information/article_2_4.png')"> 
        链聚信息科技积极主动申报参与湖南省增值税电子发票电子化报销、入账、归档试点，按照《电子发票电子化报销入账归档试点验收工作指南》要求，四部委对我单位进行了工作验收，经过专家组综合评议，满足验收要求，通过验收，以下是验收通过通知；
      </Section>
      <Title size="3">
        试点工作亮点
      </Title>
      <Section  :image="require('../../../assets/information/article_2_5.png')"> 
        创新地将区块链技术运用到了档案领域，基于公司自主可控研发的区块链底层技术，采用SM2非对称加密和签名算法、SM3哈希算法等建立了区块链档案数据平台，实现了电子会计档案的四性检测和上链存证，保证电子会计档案在创建、归档、保存、移交、查询、利用全流程的真实性、完整性、防篡改。
      </Section>
    </Article>
  </Page>
</template>
<script>
//------------------------------------------------------------------------------
/**
 * 定义文章的简要信息；这些信息将会共享给“新闻资讯”页面用于组装为资讯列表；
 */
import { createArticle } from "../../../components/article-template/article-header.js";

// 文章封面图需要直接导入；
import TITLE_IMAGE from "../../../assets/information/article_2_1.png";

// 创建不包含正文的文章头部；
const ARTICLE_HEADER = createArticle({
  title: "喜报！热烈祝贺链聚信息科技试点通过！",
  time: { year: 2022, month: 12, day: 12 },
  titleImage: TITLE_IMAGE,
  summary: "链聚信息科技公司关于电子发票电子化报销、入账、归档试点验收通过，能顺利通过，离不开公司每位同仁的支持和付出，同时感谢各界人士的鼓励和帮助！希望新的一年大家继续加油，再创辉煌！",
});

//------------------------------------------------------------------------------

export default {
  name: "Article-1",

  header: ARTICLE_HEADER,

  data() {
    return {
      article: ARTICLE_HEADER,
    };
  },
};
</script>
<style lang="less" scoped></style>
