<template>
    <Page style="padding-top: 80px">
        <Article ref="article" :title="article.title" :title-image="article.titleImage" :time="article.formattedTime"
            :summary-text="article.summary">
            <!-- 正文 -->
            <Title size="3">
                低代码与零代码对比简析
            </Title>

            <Section >
                行业用户常常因为低代码和零代码的概念区分而造成产品选型与实践的困扰，他们分别面向不同类型的行业用户。
            </Section>
            <Section :image="require('../../../assets/information/article_10_2.png')">
                低代码是基于模型与封装的功能组件，通过可视化设计和可视化编程语言系统快速展开应用程序开发与部署，从而降低代码编写的工作量，实现高效敏捷的应用程序开发，服务于技术人员为主；
            </Section>
            <Section>
                零代码是指用户无需编程便可快速构建应用程序的开发平台，零代码服务于非技术人员为主。
            </Section>

            <Title size="3">
                低代码与零代码的共性
            </Title>
            <Section>
                低代码和零代码通常为应用程序平台即服务的产品，相比传统开发的优点是：为客户提供快速开发的环境，无需单独购买和维护服务器，中间件等技术基础设施，节约大量的人力成本和开支。<br/>
                低代码和零代码共同的愿景：减少工作量，实现全民开发，在产品能力上两者不断融合与借鉴，不断提升产品成熟度及客户服务能力。
            </Section>

            

            <Title size="3">
                用户实践动因
            </Title>
            <Section :image="require('../../../assets/information/article_10_3.png')">
                各类行业用户出于不同理由开启了低代码或零代码的实践，2022年我们国内市场调研显示 
            </Section>
            <Section :image="require('../../../assets/information/article_10_4.png')">
                用户实践动因调查如下：<br/>
                技术服务商的产品推荐寻求合作占60%<br/>
                需求推动（技术部推动、业务部推动）21.9%<br/>
                新技术的主动尝试占8.5%<br/>
                同业其他组织/个人的影响与推荐6.7%<br/>
                政策性推动1.9%<br/>
                其他1%<br/>
                其中被动性的实践：68.6%<br/>
                在被动推荐或为执行某项目标（如政策导向）等因素的驱动下逐渐关注或认可低代码/零代码技术，进而展开投入。主动性的实践：30.4%出于主动的意愿与应用需求而展开低代码/零代码技术投入的企业与机构占比情况。<br/>
                而美国低代码的渗透率2020年就已经达到75%，从2022年数字化工厂转型调研报告，德国的低代码自动化如下：10%已实施，43%已建立试点，40%推广中，7%未计划。
            </Section>
           
            <Title size="3">
                低代码将成为各行业数字化转型的主要工具：
            </Title>
            <Section >
                降低软件系统采购成本。<br/>
                贴合业务场景，低代码降低程序开发和设计门槛，让一线业务人员参与到系统配置和开发，打造更加贴合业务场景的应用系统。<br/>
                柔性拓展，系统变得更加灵活以及减少新的BUG的产生。<br/>
                低代码开发平台可以实现快速迭代，帮助企业适应瞬息万变的市场环境。
            </Section>
        </Article>
    </Page>
</template>
<script>

import { createArticle } from "../../../components/article-template/article-header.js";

//------------------------------------------------------------------------------
/**
 * 定义文章的简要信息；这些信息将会共享给“新闻资讯”页面用于组装为资讯列表；
 */

// 文章封面图需要直接导入；
import TITLE_IMAGE from "../../../assets/information/article_10_1.png";

// 创建不包含正文的文章头部；
const ARTICLE_HEADER = createArticle({
    title: "一张图让你明白低代码和零代码的区别",
    time: { year: 2023, month: 4, day: 10 },
    titleImage: TITLE_IMAGE,
    summary: "低代码开发平台可以实现快速迭代，帮助企业适应瞬息万变的市场环境。",
});

//------------------------------------------------------------------------------

export default {
    name: "Article-10",

    header: ARTICLE_HEADER,

    data() {
        return {
            article: ARTICLE_HEADER,
        };
    },
};
</script>
<style lang="less" scoped></style>
  