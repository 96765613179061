import { mapGetters } from "vuex";

const ResponsiveBase = {
  inject: ["pageIndicator"],
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["screenWidth"]),

    isMobile(){
      return this.screenWidth <= 850;
    },

    isMobile1000(){
      return this.screenWidth <= 1000;
    },

    isNormal(){
      return ! this.isMobile;
    }
  },

  methods: {},
};

export { ResponsiveBase };
