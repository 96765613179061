<template>
  <div class="article article-text">
    <Title size="1">
      <slot name="title">
        <template v-for="(line, i) in titleLines" :key="i">
          <p> {{ line }} </p>
        </template>
      </slot>
    </Title>
    <div class="article-time">
      <slot name="time" :time="$props.time">
        {{ $props.time }}
      </slot>
    </div>
    <el-divider></el-divider>
    <div class="article-body font-size-4">
      <!-- 封面图 -->
      <div class="title-image">
        <slot name="titleImage">
          <img :src="this.$props.titleImage" />
        </slot>
      </div>
      <!-- 摘要 -->
      <div v-if="$props.summary || $props.summaryText" class="article-summary">
        <Section image-class="article-summary-image" :text="$props.summaryText">
          <slot name="summary">
            <template v-for="(line, i) in summaryLines" :key="i">
              <p> {{ line }} </p>
            </template>
          </slot>
        </Section>
      </div>
      <!-- 内容 -->
      <div class="article-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { utils } from "./utils";
export default {
  name: "Article",

  props: {
    title: {
      type: String,
    },
    time: {
      type: String,
    },
    summary: {
      type: Boolean,
      default: false,
    },
    summaryText: {
      type: String,
    },
    titleImage: {
      type: Object,
    },
  },

  computed: {
    titleLines() {
      const title = this.$props.title;
      if (!title) {
        return [];
      }
      // 分隔为多行文本；保留连续多个换行回车符产生的空白行；
      const lines = utils.splitLines(title, "\r\n", "\r", "\n");
      return lines;
    },

    summaryLines() {
      const summary = this.$props.summaryText;
      if (!summary) {
        return [];
      }
      // 分隔为多行文本；保留连续多个换行回车符产生的空白行；
      const lines = utils.splitLines(summary, "\r\n", "\r", "\n");
      return lines;
    },
  },

  inject: ["pageIndicator"],

  methods: {},
};
</script>
<style lang="less" scoped>
.article {
  padding: 65px 15px;
  max-width: 840px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .article-time {
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #727272;
    line-height: 28px;
    margin-top: 24px;
  }

  .article-summary {
    font-size: 20px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #727272;
    line-height: 36px;
    margin: 0;
  }

  .article-summary-image {
    width: 80%;
    height: 650px;
    margin-top: 26px;
  }

  .article-body {
    width: 100%;
    height: fit-content;
    padding: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;
    row-gap: 20px;
  }

  .title-image {
    //width: 80%;
    //height: 600px;

    img {
      width: 100%;
      height: 100%;
      //object-fit: scale-down;
      object-fit: contain;
    }
  }

  .article-text {
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #727272;
  }

  .article-content {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    //margin-top: 36px;
  }
}

@media screen and (max-width: 850px) {
  .article {
    padding: 0px 20px;
    width: 100%;

    .article-title {
      font-size: 30px;
      line-height: 40px;
    }

    .article-time {
      font-size: 14px;
    }

    .article-summary {
      font-size: 14px;
    }

    .cover {
      height: auto !important;
    }
  }
}
</style>
