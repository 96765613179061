

function formatTime(articleTime) {
  check(articleTime);
  return articleTime.year + "-" + formatDigits2Number(articleTime.month) + "-" + formatDigits2Number(articleTime.day);
}

function formatYearMonth(articleTime) {
  check(articleTime);
  return articleTime.year + "-" + formatDigits2Number(articleTime.month);
}

/**
 * 格式化 2 位数整数；
 * @param {Numbuer} v 
 */
function formatDigits2Number(v){
  if (!Number.isInteger(v)) {
    throw "The number[" + v + "] is not a integer!";
  }
  if (v < 0 || v > 99) {
    throw "The number[" + v + "] is out of bound! It must ne 1 or 2 digits.";
  }
  if (v < 10) {
    return "0".concat(v.toString());
  }
  return v.toString();
}

function check(articleTime){
  if (articleTime.month < 1 || articleTime.month > 12) {
    throw "Wrong month [" + articleTime.month + "]!";
  }
  if (articleTime.day < 1 || articleTime.day > 31) {
    throw "Wrong day [" + articleTime.day + "]!";
  }
  if (!Number.isInteger(articleTime.year)) {
    throw "The year[" + articleTime.year + "] is not a integer!";
  }
}

/**
 * 将文本分隔为多行；
 * 
 * 使用多个分隔符时，每个分隔符将依次用于分隔，
 * 
 * 首个分隔符先被用于分隔输入文本并得到临时的行列表，其后的分隔符再次对行列表的每一行再次进行分隔，然后合并得到新的临时行列表；
 * 
 * 如此依次执行后得到最终的行；
 * 
 * 如果多个分隔符之间的匹配范围存在包含关系的，应该将更精确的分隔符排列在前，可以避免难以预期的结果；
 * 
 * 例如，按换行符分隔文本，应该如下：
 * 
 *     splitLines(text, "\r\n", "\r", "\n");  
 * 
 * 
 * @param {*} text 要分隔文本；
 * @param  {...any} speperators 分隔符；可以设置多个分隔符；
 * @returns 
 */
function splitLines(text, ...speperators) {
  if (!text) {
      return [text];
  }
  if (!speperators || speperators.length == 0) {
      return [text];
  }
let lines = [text];
let tempLines = [];
for (let i = 0; i < speperators.length; i++) {
  const sep = speperators[i];
  for (let j = 0; j < lines.length; j++) {
    const lineItem = lines[j];
    const sublines = lineItem.split(sep);
    tempLines.push(...sublines);
  }
  lines = tempLines;
  tempLines = [];
}
return lines;
}

const utils = {
  formatTime,
  formatYearMonth,
  formatDigits2Number,
  check,

  splitLines,
};

export { utils };
