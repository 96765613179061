<template>
    <Page style="padding-top: 80px">
        <Article ref="article" :title="article.title" :title-image="article.titleImage" :time="article.formattedTime"
            :summary-text="article.summary">
            <!-- 正文 -->
            <Title size="3">
                什么是低代码
            </Title>
            <Section>
                低代码的英文名字叫low code，但是它一点儿也不low，是一种让小白也可以轻松开发软件产品的工具；可以让不精通编程的财务、行政、销售等都可以成为软件开发者，而且产品开发效率是普通代码产品的6倍，这是什么概念，也就是平常需要6个程序员完成的内容，用低代码1个程序员就可以做到。
            </Section>
            <Section>
                那么这么神奇的低代码到底是什么呢？低代码其实是一种可视化的应用程序开发方法，使用者会在低代码平台的界面中，根据自己的需求，选择固定模块来编辑成自己需要的内容，从而生产出自己的软件产品，就如各种食品模具，里面放入自己喜欢的材料，生产出自己喜欢的食品，但是食品模具的制作还得专业人士来，低代码平台上的代码模块，是程序员们通过不断打磨提炼编译好的。
            </Section>

            <Title size="3">
                程序员从此走向神坛了吗？
            </Title>
            <Section :image="require('../../../assets/information/article_8_3.jpg')">
                那么程序员们要从此走向神坛了吗，恰恰相反低代码模式的出现，可以帮助程序员们从增删改查这种重复高，价值低的劳动中解脱出来，从而专注于富有创造性和业务价值的流程上，从而生产出更多丰富的模板组件。
            </Section>

            <Title size="3">
                低代码一场正在到来的IT革命
            </Title>
            <Section :image="require('../../../assets/information/article_8_1.png')">
            </Section>
            <Section>
                低代码平台的出现，解决了传统软件开发中长期面临的问题，如:开发周期长、成本高、技术门槛高等；低代码提供可视化开发界面，用户可以快速构建软件；
            </Section>
            <Section>
                据不完全统计，目前中国的低代码平台开发的渗透率不到5%，而美国的渗透率2020年达到75%，由此可见我国的低代码平台仍有巨大的发在潜力；公开数据显示，2018年全球低代码开发平台的市场规模已达到56亿美元，2020年达84亿美元，预计2021年超过百亿美元，复合增长率为45.2%，其中中国市场将是重要的增量市场，海比研究数据表明，预计到2024年将达到百亿量级，未来，伴随着工业互联网、新基建政策的落地，企业数字化建设需求逐步提升，将进一步加大企业应用开发需求，低代码开发市场空间会更大；随着市场走上成熟，优秀企业将会受到资本青睐，诞生出新的巨头。
            </Section>

        </Article>
    </Page>
</template>
<script>

import { createArticle } from "../../../components/article-template/article-header.js";

//------------------------------------------------------------------------------
/**
 * 定义文章的简要信息；这些信息将会共享给“新闻资讯”页面用于组装为资讯列表；
 */

// 文章封面图需要直接导入；
import TITLE_IMAGE from "../../../assets/information/article_8_2.png";

// 创建不包含正文的文章头部；
const ARTICLE_HEADER = createArticle({
    title: "1分钟秒懂什么是低代码",
    time: { year: 2023, month: 3, day: 31 },
    titleImage: TITLE_IMAGE,
    summary: "据不完全统计，目前中国的低代码平台开发的渗透率不到5%，而美国的渗透率2020年达到75%，由此可见我国的低代码平台仍有巨大的发在潜力。",
});

//------------------------------------------------------------------------------

export default {
    name: "Article-8",

    header: ARTICLE_HEADER,

    data() {
        return {
            article: ARTICLE_HEADER,
        };
    },
};
</script>
<style lang="less" scoped></style>
  