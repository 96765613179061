<template>
    <Page style="padding-top: 80px">
        <Article ref="article" :title="article.title" :title-image="article.titleImage" :time="article.formattedTime"
            :summary-text="article.summary">
            <!-- 正文 -->
            <Title size="3">
                电子档案，将全面支撑国家大数据战略
            </Title>
            <Section>
                电子档案大数据管理，是抢抓新时代产业变革新机遇的战略选择！数据是新时代重要的生产要素，是国家基础性战略资源。大数据是数据的集合，以容量大、类型多、速度快、精度准、价值高为主要特征，是推动经济转型发展的新动力，是提升政府治理能力的新途径，是重塑国家竞争优势的新机遇。（摘：“十四五”大数据产业发展规划）
            </Section>
            <Title size="3">
                电子档案管理应顺势而为
            </Title>
            <Section>
                电子会计档案归档，是企业合规的必要条件之一，财政部国家档案局2020年6号文件，明确了电子会计凭证为合规关键要素，企业应顺势而为，乘势而上，尽早引进电子档案管理系统，规范电子档案管理工作。
            </Section>
            <Section :image="require('../../../assets/information/article_7_1.png')">
                新一轮科技革命蓬勃发展，大数据与 5G、云计算、人工智能、区块链等新技术加速融合，重塑技术架构、产品形态和服务模式，推动经济社会的全面创新。各行业各领域数字化进程不断加快，基于大数据的管理和决策模式日益成熟，电子会计档案管理系统日益完善，真正实现了产业降本增效。
            </Section>


            <Title size="3">
                电子档案向业务深度融合发展
            </Title>
            <Section>
                国家大力推进电子发票单套制，是适应新形势下电子商务、电子政务发展的必然选择，是实现会计凭证报销入账归档全流程电子化的需要，很好地避免重复报销、虚假入账、篡改信息等行为，规避财务造假、 偷逃税款等行为。
            </Section>
            <Section :image="require('../../../assets/information/article_7_2.png')">
                以电子发票为切入点，推动电子文件仅以电子化形式归档保管，可以为其他不同种类电子文件实施电子化单套制管理提供经验。企事业单位电子文件单套制归档从会计系统向管理系统、工程技术系统、科研系统等更广泛的领域扩展，财务类电子会计档案起到典型的示范作用。
            </Section>
            <Section>
                组织结构职能的发展变化、部门间的紧密配合，与电子文件全流程电子化管理两者之间相辅相成，相互促进，共同支撑组织机构的有序高效运作与业务开展。在档案的形成过程中，业务部门与档案部门共同配合，进行整体数据梳理，制定归档管理规范，电子档案在业务应用中发挥的积极作用，使业务管理与档案管理越发密不可分，从而打破业务孤岛，沉淀数据资产，挖掘数据价值，真正做到数据为企业赋能。
            </Section>
            <Section :image="require('../../../assets/information/article_7_3.png')">
               
            </Section>


            <Title size="3">
                让业务流、资金流、数据流三流合一
            </Title>
            <Section>
                在档案管理领域，打造数据价值链，业务完整证据链，充分发挥数据及档案价值，让业务流、资金流、数据流三流合一，大数据分析透视业务状态，高效决策，敏捷响应市场。
            </Section>
            <Section>
                只有完成三流合一的数字化，企业才能够实时判断哪条业务线最赚钱，企业应该把钱花在哪条业务线才能实现更好发展，以及把钱花在哪个时间节点可以产生最大的经营价值。
            </Section>
            <Section>
                电子档案管理系统，快来找我们预订！作为全国第三批电子发票报销入账归档的试点单位，有责任也有义务为电子发票的推广应用尽一份力，我们的目标是让数据活起来用起来！
            </Section>
            <Section :image="require('../../../assets/information/article_7_4.png')">
               
            </Section>

        </Article>
    </Page>
</template>
<script>

import { createArticle } from "../../../components/article-template/article-header.js";

//------------------------------------------------------------------------------
/**
 * 定义文章的简要信息；这些信息将会共享给“新闻资讯”页面用于组装为资讯列表；
 */

// 文章封面图需要直接导入；
import TITLE_IMAGE from "../../../assets/information/article_7_1.png";

// 创建不包含正文的文章头部；
const ARTICLE_HEADER = createArticle({
    title: "电子档案管理，让企业数据活起来用起来！",
    time: { year: 2023, month: 3, day: 23 },
    titleImage: TITLE_IMAGE,
    summary: "电子档案大数据管理，是抢抓新时代产业变革新机遇的战略选择！数据是新时代重要的生产要素，是国家基础性战略资源。",
});

//------------------------------------------------------------------------------

export default {
    name: "Article-7",

    header: ARTICLE_HEADER,

    data() {
        return {
            article: ARTICLE_HEADER,
        };
    },
};
</script>
<style lang="less" scoped></style>
  