<template>
  <div class="article-title" :class="'font-size-' + fontSize">
    <slot :text="text"> {{ text }} + jjj </slot>
  </div>
</template>
<script>
export default {
  name: "Title",

  props: {

    text:{
      type:String,
    },

    /**
     * 标题大小；
     *
     * 可选值： [1 - 6] ，分别对应的字号为 [18 - 8] ；
     */
    size: {
      type: Number,
      default: 1,
    },
  },

  computed: {
    fontSize() {
      if (this.$props.size <= 1) {
        return 1;
      }

      if (this.$props.size >= 6) {
        return 6;
      }

      return this.$props.size;
    },
  },

  inject: ["pageIndicator"],

  methods: {},
};
</script>
<style lang="less" src="./article.less" />
<style lang="less" scoped>
.article-title {
  width: 100%;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 450;
  color:  #040420;
  
}

@media screen and (max-width: 850px) {
}
</style>
