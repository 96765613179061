<template>
  <div class="head-container">
    <div class="menu-bar">
      <div class="menu-box">
        <div class="logo">
          <a href="/index.html">
            <img src="@/assets/header/linkgie.png" alt="" style="width: 140px; height: 40px; cursor: pointer" />
          </a>
        </div>
        <div class="menu">
          <!-- 菜单 -->
          <div class="fs-menu">
            <!-- 顶部菜单 -->
            <div v-if="screenWidth > 850" class="fs-wrap fs-flex fs-flex-between fs-flex-align-center">
              <div class="menu-item-group" :class="[currentPageName == '/index.html' ? 'menu-item-active' : 'menu-item']">
                <a href="/">首页</a>
                <div class="underline"></div>
              </div>

              <div
                class="menu-item-group"
                :class="[
                  currentPageName == '/lowcode.html' ||
                  currentPageName == '/blockchain.html' ||
                  currentPageName == '/archives.html' ||
                  currentPageName == '/outsourcing.html'
                    ? 'menu-item-active'
                    : 'menu-item',
                ]"
              >
                <a style="cursor: pointer">产品服务</a>
                <div class="underline"></div>
                <!-- 产品服务·二级菜单 -->
                <div v-if="currentPageName != 'aboutus'" class="sub-menu">
                  <div class="sub-menu-bar">
                    <div class="sub-menu-container">
                      <!-- <el-divider class="menu-item-divider" direction="vertical" /> -->
                      <a :class="currentPageName != '/lowcode.html' ? 'sub-menu-item' : 'sub-menu-item sub-menu-item-active'" href="/lowcode.html">
                        <p :class="currentPageName != '/lowcode.html' ? 'menu-pre' : 'menu-pre-active'">低代码</p>
                        &nbsp;•&nbsp;星河平台
                      </a>
                      <a :class="currentPageName != '/blockchain.html' ? 'sub-menu-item' : 'sub-menu-item sub-menu-item-active'" href="/blockchain.html">
                        <p :class="currentPageName != '/blockchain.html' ? 'menu-pre' : 'menu-pre-active'">区块链</p>
                        &nbsp;•&nbsp;千尺链
                      </a>
                      <a :class="currentPageName != '/archives.html' ? 'sub-menu-item' : 'sub-menu-item sub-menu-item-active'" href="/archives.html">
                        <p :class="currentPageName != '/archives.html' ? 'menu-pre' : 'menu-pre-active'">档案管理</p>
                        &nbsp;•&nbsp;四库档案
                      </a>
                      <a :class="currentPageName != '/outsourcing.html' ? 'sub-menu-item' : 'sub-menu-item sub-menu-item-active'" href="/outsourcing.html">
                        <p :class="currentPageName != '/outsourcing.html' ? 'menu-pre' : 'menu-pre-active'" class="busi" >IT咨询·软件定制</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="menu-item-group" :class="[currentPageName == '/download.html' ? 'menu-item-active' : 'menu-item']">
                <a href="/download.html">下载中心</a>
                <div class="underline"></div>
              </div>

              <div class="menu-item-group" :class="[currentPageName == '/document' ? 'menu-item-active' : 'menu-item']">
                <a target="_blank" href="https://developers.linkgie.com">文档</a>
                <div class="underline"></div>
                <div class="sub-menu">
                  <div class="sub-menu-bar">
                    <div class="sub-menu-container documents-menu-bar">
                      <a class="sub-menu-item" target="_blank" href="https://developers.linkgie.com/docs/galaxy/">星河低代码·Galaxy</a>
                      <!-- <a class="sub-menu-item" target="_blank" href="https://developers.linkgie.com/docs/flexue/">前端·FlexUE</a> -->
                      <a class="sub-menu-item" target="_blank" href="https://developers.linkgie.com/docs/blockchain/">区块链·千尺链</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="menu-item-group" :class="[currentPageName == '/information.html' ? 'menu-item-active' : 'menu-item']">
                <a href="/information.html">新闻资讯</a>
                <div class="underline"></div>
                <!-- 没有子菜单 -->
              </div>
              <div class="menu-item-group" :class="[currentPageName == '/about.html' ? 'menu-item-active' : 'menu-item']">
                <a href="/about.html">关于我们</a>
                <div class="underline"></div>
                <!-- 没有子菜单 -->
              </div>
            </div>
            <!-- 侧边菜单 -->
            <div v-else class="fs-menu-mobile fs-flex fs-flex-align-center">
              <div class="mobile-wrap">
                <el-icon @click="drawer = true">
                  <Menu />
                </el-icon>
              </div>
            </div>
            <el-drawer v-model="drawer" size="280px" mode="vertical" :with-header="false" router>
              <template #header>
                <!-- <template #header="{ close }"> -->
                <div class="menu_mobile_header fs-flex fs-flex-between">
                  <div class="mobile-wrap fs-flex fs-flex-align-center"></div>
                </div>
              </template>
              <!-- el-menu -->
              <el-menu style="border-right: 0" :default-openeds="['/products', '/documents']" @select="drawer = false">
                <el-menu-item index="/index.html">
                  <a href="/index.html"><span>首页</span></a>
                </el-menu-item>
                <el-sub-menu index="/products" :expand-close-icon="expandCloseIcon" :expand-open-icon="expandOpenIcon">
                  <template #title>产品服务</template>
                  <el-menu-item index="/lowcode.html">
                    <a href="/lowcode.html">
                      <span class="sub-menu-item-text"><span class="menu-pre">低代码</span> &nbsp;•&nbsp;星河平台</span>
                    </a>
                  </el-menu-item>
                  <el-menu-item index="/blockchain.html">
                    <a href="/blockchain.html">
                      <span class="sub-menu-item-text"><span class="menu-pre">区块链网络</span> &nbsp;•&nbsp;千尺链</span>
                    </a>
                  </el-menu-item>
                  <el-menu-item index="/archives.html">
                    <a href="/archives.html">
                      <span class="sub-menu-item-text"><span class="menu-pre">档案管理</span> &nbsp;•&nbsp;四库档案</span>
                    </a>
                  </el-menu-item>
                  <el-menu-item index="/outsourcing.html">
                    <a href="/outsourcing.html">
                      <span class="sub-menu-item-text"><span class="menu-pre busi">IT咨询·软件定制</span></span>
                    </a>
                  </el-menu-item>
                </el-sub-menu>
                <el-menu-item index="/download.html">
                  <a href="/download.html">
                    <span>下载中心</span>
                  </a>
                </el-menu-item>
                <el-sub-menu index="/documents" @click="gotoDocuments">
                  <template #title>文档</template>

                  <el-menu-item
                    ><a target="_blank" href="https://developers.linkgie.com/docs/galaxy/"
                      ><span class="sub-menu-item-text">星河低代码·Galaxy</span></a
                    ></el-menu-item
                  >
                  <!-- <el-menu-item
                    ><a target="_blank" href="https://developers.linkgie.com/docs/flexue/"
                      ><span class="sub-menu-item-text">前端·FlexUE</span></a
                    ></el-menu-item
                  > -->
                  <el-menu-item
                    ><a target="_blank" href="https://developers.linkgie.com/docs/blockchain/"
                      ><span class="sub-menu-item-text">区块链·千尺链</span></a
                    ></el-menu-item
                  >
                </el-sub-menu>
                <el-menu-item index="/information.html">
                  <a href="/information.html">
                    <span>新闻资讯</span>
                  </a>
                </el-menu-item>
                <el-menu-item index="/about.html">
                  <a href="/about.html">
                    <span>关于我们</span>
                  </a>
                </el-menu-item>
              </el-menu>
            </el-drawer>
          </div>
        </div>
        <div class="login">
            <a href="http://lowcode.linkgie.com" target="_blank">
              <img src="@/assets/header/user.svg" alt="登录|注册 低代码平台" style="width: 20px; height: 20px; cursor: pointer; color: brown;" />
              <span>登录 | 注册</span>
            </a>
        </div>
      </div>
    </div>


    <div class="contact-us">
      <img src="@/assets/header/customer-3-300x300.png" />
  
      <div class="contact-us-hint">
        <span>扫码咨询</span>
      </div>
  
      <div class="contact-code">
        <img src="@/assets/header/contact-code-spring-300x300.png"/>
      </div>
    </div>
  </div>

</template>
<script>
import { ArrowUp, ArrowDown } from "@element-plus/icons-vue";
import { mapGetters } from "vuex";

export default {
  name: "Header",

  props: {
    routePath: {
      type: String,
    },
  },

  data() {
    const routePath = this.$props.routePath;
    return {
      drawer: false,
      activeIndex: "/home",

      expandOpenIcon: ArrowUp,
      expandCloseIcon: ArrowDown,
      currentPageName: routePath,
    };
  },
  created() {},
  inject: ["pageIndicator"],
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["screenWidth"]),
  },
  watch: {
    $route(to, from) {
      console.log(from.path); //从哪来
      console.log(to.path); //到哪去
      this.currentPageName = to.path;
    },
  },
  mounted() {
    // this.currentPageName = this.$router.currentRoute.value.path;
  },

  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    gotoDocuments() {
      // window.location.href = 'https://developers.linkgie.com/docs';
      window.open("https://developers.linkgie.com/docs", "_blank");
    },

    routeTo(route) {
      if (route.endsWith(".html")) {
        document.location.href = route;
      } else {
        console.error("上下文不允许使用路由机制！");
        // this.$router.push(route);
      }
    },
  },
};
</script>
<style lang="less" scoped>

.contact-us {
  position:fixed;

  top: calc(50vh - 45px);
  right: 20px;
  width: 90px;
  height: 90px;

  box-sizing: border-box;
  border-style: solid;
  border-color: #f0f2f9;
  border-width: 2px;

  border-radius: 45px;

  @media screen and (max-width: 850px) {
      top: calc(50vh - 30px);
      right: 10px;
      width: 60px;
      height: 60px;
  }
}

.contact-us:hover {
  // 大小发生变化之后，为了维持图标的中心位置不动，因此要调整 top 、right 坐标减少增加高度和宽度的一半；
  top: calc(50vh - 49px);
  right: calc(20px - 4px);
  width: 98px;
  height: 98px;

  border-width: 4px;
  border-color: #d3dce6;

  border-radius: 49px;

  cursor:pointer;

  transition-duration: .4s;

  @media screen and (max-width: 850px) {
      top: calc(50vh - 32px);
      right: calc(10px - 4px);
      width: 64px;
      height: 64px;
  }
}


.contact-us .contact-us-hint {
  position:absolute;

  top: 96px;
  width: 100%;
  height: 1.8rem;

  border-radius: 1.2rem;
  border-width: 1px;
  border-color: #ffffff00;
  background-color: #ffffff00;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 850px) {
      top: 56px;
      width: 100%;
      height: 1.8rem;
  }
}

.contact-us .contact-us-hint span{
  @media screen and (max-width: 850px) {
    font-size: x-small;
  }
}

.contact-us:hover .contact-us-hint {
  // 由于父 div 高度减少 2 ，为维持相对于浏览器底部的高度不变，因此高度要增加 2 ；
  top:98px;
  border-style: solid;
  border-color: #b4b6b8;
  
  background-color: #58a0ad37;

  transition-duration: .4s;

  @media screen and (max-width: 850px) {
      top: 58px;
      width: 100%;
      height: 1.6rem;
  }
}


.contact-us .contact-code {
  position:absolute;

  top: 150px;
  right: -15px;

  width: 120px;
  height: 0;

  border-radius: 10px;

  background-color: #58a0ad37;
  padding: 6px;

  visibility:hidden;

  box-sizing: border-box;

  @media screen and (max-width: 850px) {
    top: 90px;
    right: -8px;

    width: 80px;
    height: 80px;

    visibility:visible;
  }
}

.contact-us:hover .contact-code {
  // 由于父 div 高度减少 2 ，为维持相对于浏览器底部的高度不变，因此高度要增加 2 ；
  top:150px;
  right: -13px;

  visibility:visible;

  width: 120px;
  height: 120px;
  border-radius: 10px;

  transition-duration: .4s;

  @media screen and (max-width: 850px) {
    top: 90px;
    right: -8px;

    width: 80px;
    height: 80px;

    visibility:visible;
  }
}

div {
  color: #727272;
  font-weight: 400;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
}

a {
  color: #727272;
}

.head-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 65px;
  padding: 0px 24px;

  z-index: 5000;

  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(214, 214, 218, 0.45);
}

.menu-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.menu-box {
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: auto;
    min-width: 160px;
    height: 40px;
  }
}

.menu {
  max-width: 50%;
  min-width: 500px;
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 850px) {
    min-width: 60px;
  }
}

.fs-menu-mobile {
  height: 100%;
  justify-content: flex-end;

  .mobile-wrap {
    color: #000000;
    font-size: 29px;

    &:hover {
      cursor: pointer;
    }
  }
}

.sub-menu-item-text {
  font-size: 14px;
  font-weight: 400;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.menu_mobile_header {
  display: flex;
}

.login {
  padding-right: 0px;
  width: 200px;
}

.login a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 0.5rem;

  font-weight: bold;
}

.el-menu {
  justify-content: flex-start !important;
}

:deep(.el-menu .el-menu-item) {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #727272;
}

:deep(.el-menu .el-sub-menu__title) {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #727272;
}

:deep(.el-menu .el-icon) {
  display: none;
}

:deep(.el-menu .is-active) {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #040420 !important;
}

:deep(.el-menu) {
  border-bottom: none !important;
}

.el-button {
  width: 88px;
  height: 32px;
}

.menu-pre {
  width: 70px;
  text-align: justify;
  text-align-last: justify;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  color: #727171;
  font-weight: 500;
}

.menu-pre-active {
  color: #040420;
  width: 70px;
  text-align: justify;
  text-align-last: justify;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 500;
}

.menu-pre.busi{
  width: 145px;
}
.menu-pre-active.busi{
  width: 145px;
} 

:deep(.el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title) {
  border-bottom: 4px solid var(--el-menu-active-color);
  color: var(--el-menu-active-color);
}

:deep(.el-menu--horizontal > .el-menu-item.is-active) {
  border-bottom: 4px solid var(--el-menu-active-color);
  color: var(--el-menu-active-color);
}

/**---------- 菜单的样式 ----------**/

.fs-wrap {
  height: 100%;

  // a {
  //   margin: 0 20px;
  // }
}

.fs-menu {
  width: 100%;
}

.menu-item-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-item {
  position: relative;
  padding: 8px 20px;

  color: #727272;
  font-weight: 400;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;

  a {
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    //line-height: 34px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  }

  :hover {
    color: #040420;
    font-weight: 500;
  }
}

.menu-item:hover .underline {
  visibility: visible;
}

a.menu-item:hover {
  color: #040420;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 500;
}

.menu-item-active {
  color: #040420;
  font-weight: 500;
  padding: 8px 20px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  position: relative;

  > a {
    color: #040420;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
  }

  .underline {
    visibility: visible;
  }
}

.underline {
  width: 20px;
  height: 4px;
  background: #2e64f3;
  position: absolute;
  bottom: -14px;
  visibility: hidden;
}

.menu-item.menu-light-mode {
  color: #727171;

  a {
    color: #727171;
  }
}

.menu-item-active.menu-light-mode {
  color: #000000;

  a {
    color: #000000;
  }
}

.sub-menu {
  visibility: hidden;
  //   visibility: visible;

  width: 100%;
  z-index: 10000;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30px;
  left: 0px;
  right: 0px;
  padding: 10px;
}

.sub-menu-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  row-gap: 4px;
  align-items: flex-start;
  padding: 12px 16px;
}

.sub-menu-bar {
  position: relative;
  top: 22px;
  left: -6px;
  right: 0px;
  height: auto;
  width: 200px;
  /* padding: 0 40px; */
  background-color: #ffffff;
  border-top-color: rgba(241, 241, 241, 0.05);
  border-top-style: solid;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sub-menu-bar .sub-menu-container {
  height: 168px;
}

.sub-menu-bar .sub-menu-container.documents-menu-bar {
  // 三个子菜单项；
  // height: 134px;

  // 两个子菜单项；
  height: 90px;
}

.sub-menu-item {
  font-size: 14px !important;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  height: 34px;
  color: #727272;
  font-weight: 400;
  display: flex;
  flex-direction: row;
}

.sub-menu-item-active {
  color: #040420;
  font-weight: 500;
}

.sub-menu-item:hover {
  color: #040420;
  font-weight: 500;
}

.menu-light-mode .sub-menu-bar {
  color: #727171;
  background-color: rgba(241, 241, 241, 0.2);

  a {
    color: #727171;
  }
}

.menu-light-mode .sub-menu-item:hover {
  color: #000000;
}

.menu-item:hover > .sub-menu {
  //   display: flex;
  visibility: visible;
}

.menu-item-active:hover > .sub-menu {
  //   display: flex;
  visibility: visible;
}

.sub-menu:hover {
  //   display: flex;
  visibility: visible;
}

/** 产品中心·二级菜单 */

.product-types-menu {
  height: 181px;
}

// .contact-menu-bar {
//   width: 100%;
//   height: 88px;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   justify-content: flex-end;
//   align-items: flex-end;
//   padding: 30px 0;
// }

.documents-menu-bar {
  margin: 0px;
}

.swiper-container {
  height: 100% !important;
}

.swiper-slide {
  height: 100%;
}

.mask-container {
  position: relative;
  padding: 0px;
  height: 100%;
}

.header-product-type-bar {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-direction: row;
  //padding: 6px 0 0 0;
  margin: 6px 1px 0px 1px;

  .swiper-wrapper {
    width: 100% !important;
    max-height: 90px;
    min-height: 90px;
    justify-content: flex-end;
  }

  @media screen and (max-width: 1550px) {
    .swiper-wrapper {
      justify-content: flex-start;
    }
  }
}

.menu-item-divider {
  width: 2px;
  padding: 0px;
  //margin: 0 0 2px 0;
  margin: -40px 0 2px 0;
  height: 0.9em;

  border-left: 1px solid #b6b6b6;
}

.header-product-type-bar-divider.el-divider--horizontal {
  margin: 0;
}

.header-product-type-box {
  width: 137px;
  color: rgba(88, 88, 88, 1);
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
}

.header-product-type-name {
  font-size: 16px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  margin-top: 2px;

  color: #b6b6b6;
}

.menu-light-mode .header-product-type-box {
  color: #727171;

  a {
    color: #727171;
  }
}
</style>
