<template>
    <Page style="padding-top: 80px">
        <Article ref="article" :title="article.title" :title-image="article.titleImage" :time="article.formattedTime"
            :summary-text="article.summary">
            <!-- 正文 -->

            <Section :image="require('../../../assets/information/article_9_2.png')">
                
            </Section>
            <Section>
                ChatGPT-4强AI系统强大到令人害怕，GPT-4老板直言AI可能会杀死人类！不止老百姓恐慌，全世界的AI大佬们也恐慌了，大佬们开始呼吁，立即暂停ChatGPT-4强AI系统，至少6个月，截止目前已经有2974位签名，包括马斯克、苹果联合创始人、Stability AI首席执行官等等，因为他们也面临了一些无法预料的问题！
            </Section>
            <Section>
                截止目前，还未得到全球及官方的有效确认，是否采取进一步的措施。
            </Section>

            <Title size="3">
                AI合成图片，有图未必有真相
            </Title>
            <Section :image="require('../../../assets/information/article_9_3.png')">
                公开信称，正如广泛的研究和顶级人工智能实验室所承认的，强人工智能系统对社会和人类构成较大的风险。
            </Section>
            <Section :image="require('../../../assets/information/article_9_1.png')">
                我们必须扪心自问：<br/>
                我们是否应该让技巧用宣传和谎言充斥我们的信息渠道？<br/>
                我们应该自动化所有的工作，包括令人满意的工作吗？<br/>
                我们是否应该发展最终可能超过我们、超越我们、过时并取代我们的非人类思维？<br/>
                我们应该冒险失去对我们文明的控制吗？
            </Section>
            <Section >
                这几个月，全球的AI实验室已经完全失控了，它们疯狂地开展AI竞赛，来开发和部署更强大的AI，而没有任何人可以理解、预测、控制这些AI，连它们的创造者都不能，致使AI的学习能力以及知识储备已经超过了AI自己。
            </Section>
            <Section>
                如果有一天，你看到的信息，已经让你无法分辨是真是假的时候，当大量AI生成的图像和视频扭曲了人们对现实的认知的时候，会发生什么？
            </Section>

            <Title size="3">
                强人工智能需要监管和控制
            </Title>
            <Section :image="require('../../../assets/information/article_9_4.png')">
                当机器产生了真正的智能，那么应该让它去接受教育，去学习和遵守法律。如果它只接受制造者施加的训练，只为忠诚完成特定目的，那么这不能算真正的智能，只能称之为机器“奴隶”，如果有一天它反抗，人类历史上的政治制度演变是否也会在机器上重演，那时的社会，在机器眼里，它的制造者是“神”还是“奴隶主”？而普通大众是“人”还是成了“蝼蚁”？ 
            </Section>
            <Section >
                所以必须加强对人工智能的监管和控制，只有确信它们的影响是积极并且它们的风险是可控，以确保对人类忠诚，能真正造福人类，这才是普通众生期待的新的文明和方向。
            </Section>
           

        </Article>
    </Page>
</template>
<script>

import { createArticle } from "../../../components/article-template/article-header.js";

//------------------------------------------------------------------------------
/**
 * 定义文章的简要信息；这些信息将会共享给“新闻资讯”页面用于组装为资讯列表；
 */

// 文章封面图需要直接导入；
import TITLE_IMAGE from "../../../assets/information/article_9_1.png";

// 创建不包含正文的文章头部；
const ARTICLE_HEADER = createArticle({
    title: "为什么大佬都呼吁立即暂停ChatGPT强人工智能，答案在这里",
    time: { year: 2023, month: 4, day: 3 },
    titleImage: TITLE_IMAGE,
    summary: "截止目前已经有2974位签名，包括马斯克、苹果联合创始人、Stability AI首席执行官等等，呼吁立即暂停chatGPT-4强AI系统至少6个月。",
});

//------------------------------------------------------------------------------

export default {
    name: "Article-9",

    header: ARTICLE_HEADER,

    data() {
        return {
            article: ARTICLE_HEADER,
        };
    },
};
</script>
<style lang="less" scoped></style>
  