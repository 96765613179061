<template>
  <Page style="padding-top: 80px">
    <Article ref="article" :title="article.title" :title-image="article.titleImage" :time="article.formattedTime"
      :summary-text="article.summary">
      <!-- 正文 -->
      <Title size="3">
        一、什么是电子会计档案
      </Title>
      <Section >
        在会计核算工作中由电子计算机直接形成或接收、传输、存储并归档,记录和反映单位经济业务事 项,具有凭证、查考和保存价值的电子会计资料。
      </Section>

      <Title size="3">
        二、管理原则和要求
      </Title>
      <Section :image="require('../../../assets/information/article_5_3.png')">
        ①单位应加强电子会计档案管理工作,建立和完善电子会计资料的形成、收集、整理、归档、保管、统计、利用、鉴定、处置等管理制度。<br/>
                        ②应将电子会计档案管理工作纳入会计人员、档案人员、相关业务人员岗位职责和绩效考核。
      </Section>
      <Section>
        ③专人负责应归档的电子会计资料收集、整理、归档等工作，定期向单位的档案管理机构及人员移交。<br/>
                        n④单位的信息技术机构应在相关会计核算系统及业务系统规划、分析、设计、实施、运维等过程中落实电子会计资料归档要求。<br/>
                        ⑤应加强电子会计档案的安全保密管理,涉密电子会计资料和电子会计档案管理按照国家有关规定执行。<br/>
                        ⑥符合国家有关规定形成(或接收)并按照本文件管理的电子会计资料，可仅以电子形式归档保存。<br/>
      </Section>
      
      <Title size="3">
        三、电子会计档案需要归档的具体内容
      </Title>
      <Section :image="require('../../../assets/information/article_5_4.png')">
        电子会计资料收集归档的资料主要有：原始凭证、记账凭证、电子会计账簿、电子财务会计报告、其它电子会计资料；（可参照表1）
      </Section>
      
      <Title size="3">
        四、企业如何应对全电发票新时代
      </Title>
      <Section>
        企业应顺应趋势，以此为契机，提升管理模式，尽早制定应对和管理的方案，引进电子会计档案管理系统，早用早受益；运用信息化、智能化技术手段，推动管理模式向精细化发展；从以下几点出发：<br/>
                        ◆积极响应国家“全电发票”的政策。加强对“全电发票”的基础知识培训。结合企业自身特点与“全电发票”特性，发现潜在的风险，制定“全电发票”应对方案。<br/>
                        ◆加强对发票全生命周期的管控。建立全套的全电发票入账、报销、归档系统，健全发票全生命周期的管控流程监督，从发票全周期考虑布局完成纸电过度。<br/>
                        ◆选择合适票税合规方案。企业需了解自身的票税状况，加强内部管理，做好应对措施，从根源上实现降本增效。<br/>
                        ◆引进电子会计档案管理系统，妥善保管电子会计资料原件的同时，电子发票及电子会计档案的推进，也为企业数字化发展带来了新的机遇。无纸化办公，大幅降低人工及耗材成本，实现降本增效目标。
      </Section>

      <Title size="3">
        五、推荐链聚信息四库电子档案管理系统
      </Title>
      <Section :image="require('../../../assets/information/article_5_5.png')">
        四库电子档案管理系统致力为客户提供适用、高效的电子会计档案管理平台 ，助力电子会计资料归档，提供更加合规、高效的解决方案
      </Section>
      <Section>
        系统提供完整的企、事业单位的信息资源、档案的收集、管理、利用及储存的平台，并引入全文检索、OCR、流媒体、密码技术、安全访问控制等技术，实现档案管理全过程的真实、完整、可用与安全。各个体系均采用模板设计，软件性价比高，对中小微企业是一个不错的选择。
      </Section>
    </Article>
  </Page>
</template>
<script>

import { createArticle } from "../../../components/article-template/article-header.js";

//------------------------------------------------------------------------------
/**
 * 定义文章的简要信息；这些信息将会共享给“新闻资讯”页面用于组装为资讯列表；
 */

// 文章封面图需要直接导入；
import TITLE_IMAGE from "../../../assets/information/article_5_1.png";

// 创建不包含正文的文章头部；
const ARTICLE_HEADER = createArticle({
  title: "企业如何做好电子会计档案管理？\r\n请收好这份指南",
  time: { year: 2023, month: 2, day: 2 },
  titleImage: TITLE_IMAGE,
  summary: "随着《电子会计档案管理规范》的不断推进，电子会计档案的管理势在必行。新规定明确了电子会计档案的保管、统计、利用、鉴定、处置等工作的要求，面对全面电子化，企业接收的增专票电子发票将如何保管？如何推进电子归档？电子会计档案管理规范解读《电子会计档案管理规范》的不断推进，标志着电子会计凭证从纸质走向电子化的趋势已经不可逆转。",
});

//------------------------------------------------------------------------------

export default {
  name: "Article-5",

  header: ARTICLE_HEADER,

  data() {
    return {
      article: ARTICLE_HEADER,
    };
  },
};
</script>
<style lang="less" scoped></style>
